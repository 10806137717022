import "@ant-design/flowchart/dist/index.css";
import React, { useEffect } from "react";
import "./App.css";
import Navigation from "./Navigation";
import { message } from "antd";

function App() {
  useEffect(() => {
    message.config({
      top: 0,
      duration: 2,
      maxCount: 1,
      rtl: true,
    });
  }, []);

  return (
    <div className="w-full h-[100vh]">
      <Navigation />
    </div>
  );
}

export default App;
