import React, { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const Navigate = useNavigate();
  useLayoutEffect(() => {
    if (localStorage.getItem("SDG_TOKEN")) {
    } else {
      Navigate("/login");
    }
  }, []);
  return <div></div>;
};

export default Auth;
