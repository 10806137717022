import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Card from "../../../Components/Card";
import Prize1 from "../../../Components/Svg/prize1";
import Prize2 from "../../../Components/Svg/prize2";
import Prize3 from "../../../Components/Svg/prize3";
import Columnchart from "../../../Components/Column";
import Usagegraph from "../../../Components/Usagegraph";
import User from "../../../Components/Svg/user";
import Post from "../../../Components/Svg/post";
import { useEffect } from "react";
import Table from "../../../Components/Tabledata";
import Tableheader from "../../../Components/TableHeader";
import Piechart2 from "../../../Components/Pie/piechart";

const Layout = ({
  Totalcount,
  TopStudent,
  PreferredGoals,
  PreferredActions,
  Monthlydata,
}) => {
  const { id, flyear } = useParams();
  const Navigate = useNavigate();
  const [Topactions, setTopactions] = useState([]);

  useEffect(() => {
    if (PreferredActions?.data?.body?.action_data) {
      let Data = [];
      PreferredActions?.data?.body?.action_data?.forEach((item, index) => {
        Data.push({ name: item?.action_desc, data: [item?.total_count] });
      });
      setTopactions(() => [...Data]);
    }
  }, [PreferredActions]);

  return (
    <div className={`h-full`}>
      {/* Breadcrumb */}
      <div className={`p-2 py-1`}>
        <ul className={`flex items-center`}>
          <li className={`mx-1 font-semibold`}>
            <NavLink to={"/college"}>Manage college</NavLink>
          </li>
          <li className={`mx-1`}>
            <i className="fa-solid fa-angle-right"></i>
          </li>
          <li className={`font-medium`}>
            {Totalcount?.data?.body?.college_name || ""}
          </li>
        </ul>
      </div>
      {/* Title */}
      <p className={`text-xl font-semibold px-2 py-3 text-Orange`}>
        College dashboard
      </p>

      {/* CARD AND SCRORE BOARD */}
      <div className={`p-2 w-full flex items-stretch`}>
        {/* CARD */}
        <div className={`w-[100%] flex items-stretch justify-around  `}>
          <div className={`w-[50%] mx-1`}>
            <Card
              bg={"bg-Orange"}
              count={Totalcount?.data?.body?.total_reg_count || ""}
              icon={<User />}
              title={"Total students participated"}
            />
          </div>
          <div className={`w-[50%] mx-1`}>
            <Card
              bg={"bg-Navy"}
              count={Totalcount?.data?.body?.total_journal_count || ""}
              icon={<Post />}
              title={"Total journal entries"}
            />
          </div>
        </div>
      </div>
      {/* PIE CHART */}
      <div className={`w-full flex items-start p-2`}>
        {/* PIE AND PROGRESS */}
        <div className={`w-[100%] mx-1 flex items-start`}>
          {/* SCORE BOARD */}
          <div className={`w-[50%]`}>
            <div
              className={`w-full rounded-md shadow-xl border-2 border-[#ccd9c5] p-2`}
            >
              {/* HEADER */}
              <Tableheader title={"Top 3 students"} />
              {/* DATA */}
              <div className={`w-full pt-4`}>
                <Table
                  heading={["Position", "Name", "Journal Posted"]}
                  loading={TopStudent?.loading}
                >
                  {TopStudent?.data?.body?.length > 0 && (
                    <tbody>
                      {TopStudent?.data?.body?.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={`w-full grid grid-cols-3 px-1 py-1`}
                          >
                            <td className={`text-left flex items-center`}>
                              <span className={`mr-1 font-bold`}>
                                {item?.place}st{" "}
                              </span>
                              {item?.place === 1 && <Prize1 />}
                              {item?.place === 2 && <Prize2 />}
                              {item?.place === 3 && <Prize3 />}
                            </td>
                            <td className={`text-center`}>
                              {item?.user_full_name}
                            </td>
                            <td className={`text-center`}>
                              {item?.journal_count}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}

                  {!TopStudent?.data?.body?.length && (
                    <tbody>
                      <tr className={`w-full grid grid-cols-3 px-1 py-1`}>
                        <td></td>
                        <td className="text-center font-semibold my-2">
                          No Data
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </div>
            </div>
          </div>
          {/* LINE GRAPH */}
          <div className="w-[50%] ml-2  shadow-xl border-2 border-[#ccd9c5] rounded-md p-3">
            <div className={`w-[96%] `}>
              <p className={`my-1 font-semibold text-lg`}>
                Frequently used SDG Action points
              </p>

              {PreferredActions?.data?.body?.action_data && (
                <Columnchart
                  data={Topactions}
                  horizontal={true}
                  title={"Actions"}
                />
              )}
              {!PreferredActions?.data?.body?.action_data?.length && (
                <p className="text-center font-semibold my-2">No Data</p>
              )}
            </div>
          </div>
        </div>
        {/* GOAL DETAIL */}
        <div className={`w-[50%] mx-1 hidden`}>
          <div
            className={`p-2 rounded-md bg-white shadow-xl border-2 border-[#ccd9c5]`}
          >
            <p className={`font-semibold text-lg`}>
              SDG goals preferred by students
            </p>
            {!PreferredGoals?.data?.body?.goal_data?.length && (
              <p className="text-center font-semibold my-2">No Data</p>
            )}
            {PreferredGoals?.data?.body?.goal_data && (
              <div className={`w-full py-2`}>
                {PreferredGoals?.data?.body?.goal_data?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`w-full  grid grid-cols-7 content-center items-center my-2`}
                      title={item?.goal_title}
                    >
                      <div className="col-span-3 flex items-center">
                        <img
                          src={item?.goal_icon}
                          alt={item?.goal_title}
                          className={`w-[50px] bg-[${item?.icon_color}] aspect-square object-contain p-2 rounded-md`}
                        />
                        <p className={`pl-2 text-sm`}>{item?.goal_title}</p>
                      </div>

                      <div
                        className={`rounded-full bg-[#ccc5] overflow-hidden h-[15px] col-span-3 mx-1 p-1`}
                      >
                        <div
                          className={`h-full w-[${item?.percent}] bg-[${item?.icon_color}] rounded-full`}
                        ></div>
                      </div>
                      <p
                        className={`col-span-1 px-1 text-sm font-medium text-left`}
                      >
                        {item?.user_count}/
                        {PreferredGoals?.data?.body?.total_user}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <p className={`font-semibold text-lg px-2`}>
        SDG goals preferred by students
      </p>
      <div className="my-4 flex items-center justify-center">
        <Piechart2 data={PreferredGoals?.data?.body?.goal_data} />
      </div>

      <div className={`mx-1 w-[80%] max-w-[1000px]`}>
        <p className={`px-2 text-md font-medium`}>
          Journal count -{" "}
          <select
            name=""
            id=""
            value={flyear}
            onChange={(e) => {
              Navigate(`/college/${id}/${e.target.value}`);
            }}
          >
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </p>
        {!Monthlydata?.data?.body?.length && (
          <p className="text-center font-semibold my-2">No Data</p>
        )}
        {Monthlydata?.data?.body && (
          <Usagegraph data={Monthlydata?.data?.body} />
        )}
      </div>
    </div>
  );
};

export default Layout;
