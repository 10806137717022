import React from "react";

const Prize3 = () => {
  return (
    <svg
      width="30"
      height="41"
      viewBox="0 0 30 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4567_4606)">
        <path
          d="M16.8508 36.0264L20.2157 30.6964C20.3104 30.5455 20.491 30.473 20.6626 30.5144L26.7946 31.969C27.1231 32.0474 27.4028 31.7204 27.2755 31.4082L22.0698 18.6484L10.9082 23.2031L16.1139 35.9628C16.2412 36.2765 16.6703 36.3135 16.8508 36.0279V36.0264Z"
          fill="#F41943"
        />
        <path
          d="M18.7121 24.6875L15.0734 33.412L11.5146 24.6875H18.7121Z"
          fill="#BA0F38"
        />
        <path
          d="M18.4002 23.2016L17.7935 24.6872L15.5517 30.1815L15.4585 30.4079L14.652 32.3848L13.193 35.9613C13.0658 36.275 12.6366 36.312 12.4561 36.0264L9.09119 30.6979C8.99649 30.5485 8.81744 30.4745 8.64431 30.5159L2.51229 31.9705C2.18379 32.0489 1.90412 31.7219 2.03138 31.4082L7.23708 18.6484L18.3987 23.2031L18.4002 23.2016Z"
          fill="#F41943"
        />
        <path
          d="M23.5965 22.3892C23.1096 22.8228 19.6663 25.7926 14.6544 25.7926C13.7118 25.7926 12.7678 25.6949 11.8459 25.4981C9.57597 25.0157 7.44516 23.9311 5.71387 22.3892L7.23947 18.6484L8.98704 19.3617L9.14537 19.4268L14.6544 21.673L20.1635 19.4268L20.3218 19.3617L22.0694 18.6484L23.595 22.3892H23.5965Z"
          fill="#BA0F38"
        />
        <path
          d="M14.6545 24.6938C21.4735 24.6938 27.0014 19.1659 27.0014 12.3469C27.0014 5.5279 21.4735 0 14.6545 0C7.83551 0 2.30762 5.5279 2.30762 12.3469C2.30762 19.1659 7.83551 24.6938 14.6545 24.6938Z"
          fill="#E37F22"
        />
        <path
          d="M14.6542 23.5916C20.8668 23.5916 25.9031 18.5553 25.9031 12.3427C25.9031 6.13007 20.8668 1.09375 14.6542 1.09375C8.44159 1.09375 3.40527 6.13007 3.40527 12.3427C3.40527 18.5553 8.44159 23.5916 14.6542 23.5916Z"
          fill="#BA6017"
        />
        <path
          d="M14.6546 22.9108C20.4872 22.9108 25.2155 18.1825 25.2155 12.3499C25.2155 6.51732 20.4872 1.78906 14.6546 1.78906C8.82201 1.78906 4.09375 6.51732 4.09375 12.3499C4.09375 18.1825 8.82201 22.9108 14.6546 22.9108Z"
          fill="#E37F22"
        />
        <path
          d="M19.9965 3.24216L5.16804 16.9904C4.47996 15.5891 4.09375 14.0146 4.09375 12.3514C4.09375 11.1173 4.30683 9.93352 4.69748 8.83259L12.1139 2.09833C12.9278 1.89708 13.7786 1.78906 14.6546 1.78906C16.6019 1.78906 18.4279 2.31881 19.9965 3.24216Z"
          fill="#F79A4D"
        />
        <path
          d="M24.4775 8.47497L10.0456 21.8547C8.59253 21.1474 7.3244 20.116 6.33594 18.8538L21.9294 4.70312C23.0318 5.75226 23.9093 7.03667 24.4775 8.47497Z"
          fill="#F79A4D"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4567_4606"
          x="0"
          y="0"
          width="29.3066"
          height="40.2188"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4567_4606"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4567_4606"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Prize3;
