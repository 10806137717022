import React, { useState } from "react";
import { AgChartsReact } from "ag-charts-react";
// import { Pie } from "@ant-design/plots";
const Piechart = () => {
  const [options, setOptions] = useState({
    data: [
      { asset: "Stocks", amount: 22 },
      { asset: "Bonds", amount: 11 },
      { asset: "Cash", amount: 21 },
      { asset: "Real Estate", amount: 43 },
      { asset: "Commodities", amount: 7 },
    ],
    title: {
      text: "Frequently used goals",
    },
    series: [
      {
        fills: ["lightBlue", "green", "yellow", "orange"],
        type: "pie",
        angleKey: "amount",
        calloutLabelKey: "asset",
        sectorLabelKey: "amount",
        showInLegend: false,
        sectorLabel: {
          color: "white",
          fontWeight: "bold",
          formatter: ({ value }) => `${value}`,
        },
      },
    ],
    // series: [
    //   {
    //     fills: ["lightBlue", "green", "yellow", "orange"],
    //     type: "pie",
    //     angleKey: "amount",
    //     legendItemKey: "asset",
    //     sectorLabel: {
    //       color: "white",
    //       fontWeight: "bold",
    //       formatter: ({ value }) => `$${(value / 1000).toFixed(0)}K`,
    //     },
    //     showInLegend: false,
    //     formatter: ({ value }) => `$${(value / 1000).toFixed(0)}K`,
    //   },
    // ],
  });
  const data = [
    { type: "分类一", value: 27 },
    { type: "分类二", value: 25 },
    { type: "分类三", value: 18 },
    { type: "分类四", value: 15 },
    { type: "分类五", value: 10 },
    { type: "其他", value: 5 },
  ];

  const customLabel = (_, datum) => {
    console.log(datum);
    return (
      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
        <div
          style={{
            width: 8,
            height: 8,
            background: "rgba(0,0,0,0.4)",
            borderRadius: "50%",
          }}
        />
        <div className="text-black">
          {datum.type} : <b>{datum.value}</b>
        </div>
      </div>
    );
  };
  const config = {
    data,
    angleField: "value",
    colorField: "type",
    scale: {
      color: {
        palette: "viridis",
      },
    },
    label: {
      text: "type",
      position: "inside",
      textAlign: "center",
      //   transform: [
      //     {
      //       type: "contrastReverse",
      //     },
      //   ],
      render: customLabel,
    },
    legend: false,
  };

  // return <Pie {...config} />;
  return <AgChartsReact options={options} />;
};

export default Piechart;
