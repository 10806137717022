import React from "react";

const Tableheader = ({ title }) => {
  return (
    <div className={`flex items-center justify-between`}>
      <p className={`text-lg font-semibold`}>{title}</p>
      <p className={`text-xl text-Violet`}>
        <i className="fa-solid fa-chart-simple"></i>
      </p>
    </div>
  );
};

export default Tableheader;
