import React, { useEffect } from "react";
import Layout from "./layout";
import { API_URL } from "../../../path";
import useApiPost from "../../../Hooks/usePost";
import { useParams } from "react-router-dom";

const Collegedata = () => {
  const { id, flyear } = useParams();
  const Totalcount = useApiPost(
    `${API_URL}college/dashboard/total_registration`
  );
  const TopStudent = useApiPost(`${API_URL}college/dashboard/top_students`);
  const PreferredGoals = useApiPost(
    `${API_URL}college/dashboard/preferred_goals`
  );
  const PreferredActions = useApiPost(
    `${API_URL}college/dashboard/preferred_actions`
  );
  const Monthlydata = useApiPost(
    `${API_URL}college/dashboard/monthly_analysis`
  );
  useEffect(() => {
    return () => {
      Totalcount.setData(null);
      TopStudent.setData(null);
      PreferredGoals.setData(null);
      PreferredActions.setData(null);
    };
  }, []);

  useEffect(() => {
    if (Totalcount.data !== null) {
    } else {
      Totalcount.postData({
        selected_college_id: id,
      });
    }
  }, [id]);
  useEffect(() => {
    if (TopStudent.data !== null) {
    } else {
      TopStudent.postData({
        selected_college_id: id,
      });
    }
  }, [id]);
  useEffect(() => {
    if (PreferredGoals.data !== null) {
    } else {
      PreferredGoals.postData({
        selected_college_id: id,
      });
    }
  }, [id]);
  useEffect(() => {
    if (PreferredActions.data !== null) {
    } else {
      PreferredActions.postData({
        selected_college_id: id,
      });
    }
  }, [id]);
  useEffect(() => {
    // if (Monthlydata.data !== null) {
    // } else {
    Monthlydata.postData({
      selected_college_id: id,
      year: parseInt(flyear),
    });
    // }
  }, [id, flyear]);

  return (
    <div className="h-full">
      <Layout
        Totalcount={Totalcount}
        TopStudent={TopStudent}
        PreferredGoals={PreferredGoals}
        PreferredActions={PreferredActions}
        Monthlydata={Monthlydata}
      />
    </div>
  );
};

export default Collegedata;
