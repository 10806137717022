import React from "react";

const Header = ({ title, button }) => {
  return (
    <div className="flex items-center justify-between">
      <p className={`text-xl font-semibold px-2 py-3 text-Orange`}>{title}</p>
      {button && (
        <button
          className="bg-Navy text-white py-1 px-3 rounded-md font-semibold"
          onClick={button}
        >
          Add college
        </button>
      )}
    </div>
  );
};

export default Header;
