
import { message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
function useApiPost(endpoint, initialData = null) {
    const Navigate = useNavigate()
    const [data, setData] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const postData = (payload) => {
        setLoading(true);
        setError(null);
        fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${localStorage.getItem("SDG_TOKEN")}`,
            },
            body: JSON.stringify(payload),
        })
            .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
            .then((result) => {
                setData(result);
                if (result.status !== 200) {
                    message.error({
                        type: "warning",
                        content: `Something went wrong`,
                    });
                }
                if (result.status === 403) {
                    Navigate('/login')
                }
            })
            .catch((err) => {
                message.error({
                    type: "warning",
                    content: `Something went wrong`,
                });
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });


    };

    return { data, loading, error, postData, setData, setLoading, setError };
}

export default useApiPost;
