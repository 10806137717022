import React from "react";
import { Graph_wave } from "../../Assets";

const Card = ({ bg, count, icon, title }) => {
  return (
    <div
      className={`${bg} w-full w-full  rounded-md text-white shadow-lg min-h-[180px] h-full relative m-1`}
    >
      {/* <div className={`flex items-center text-3xl p-2 justify-between `}>
        <p className={`text-[42px] font-semibold`}>{count}</p>
        {icon}
      </div>
      <p className={`pl-2 font-semibold text-lg mt-2`}>{title}</p> */}

      <div className="p-2 flex items-start justify-between">
        <div>
          <p className={`text-[42px] font-semibold`}>{count}</p>
          <p className={`pl-2 font-semibold text-lg mt-0`}>{title}</p>
        </div>
        {icon}
      </div>
      <div className={`absolute w-full bottom-0 flex items-center justify-end`}>
        <img src={Graph_wave} alt="graph" className={`w-[50%]`} />
      </div>
    </div>
  );
};

export default Card;
