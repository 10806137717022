import React from "react";
import Chart from "react-apexcharts";

const Usagegraph = ({ data }) => {
  const options = {
    chart: {
      type: "heatmap",
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "10px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: undefined,
      },
    },
    colors: ["#fff"],
    title: {
      text: "",
    },
    plotOptions: {
      heatmap: {
        radius: 0,
        enableShades: true,
        shadeIntensity: 0.3,
        reverseNegativeShade: true,
        distributed: true,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: "#ccc9",
              name: "0 post",
            },
            {
              from: 1,
              to: 20,
              color: "#E5A5FF",
              name: "1 - 20 post",
            },
            {
              from: 21,
              to: 30,
              color: "#C264FE",
              name: "21 - 30 post",
            },
            {
              from: 31,
              to: 1000,
              color: "#7A08FA",
              name: "30+ post",
            },
          ],
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "right",
      offsetX: -30,
      offsetY: 0,
      markers: {
        width: 70,
        radius: 1,
        height: 12,
        offsetX: -5,
        offsetY: 2,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 5,
      },
    },
  };
  if (data)
    return (
      <Chart
        options={options}
        series={data}
        type="heatmap"
        width={"100%"}
        height={
          data?.length === 1
            ? 100
            : data?.length === 2
            ? 130
            : data?.length === 3
            ? 150
            : data?.length === 4
            ? 180
            : data?.length === 5
            ? 200
            : data?.length === 6
            ? 220
            : data?.length === 7
            ? 240
            : data?.length === 8
            ? 260
            : data?.length === 9
            ? 280
            : data?.length === 10
            ? 310
            : data?.length === 11
            ? 330
            : data?.length === 12
            ? 370
            : 400
        }
      />
    );
};

export default Usagegraph;
