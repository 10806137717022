import React from "react";

const Loader = () => {
  return (
    <div className="w-full h-full absolute top-0 left-0 bg-[#ccc8] flex items-center justify-center z-[9999999]">
      <p className="text-Orange text-[62px] ">
        <i className="fa-solid fa-spinner fa-spin"></i>
      </p>
    </div>
  );
};

export default Loader;
