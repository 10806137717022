import React from "react";

const Prize1 = () => {
  return (
    <svg
      width="30"
      height="40"
      viewBox="0 0 30 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4567_4578)">
        <path
          d="M16.7579 35.8082L20.1018 30.5116C20.1959 30.3616 20.3753 30.2895 20.5458 30.3307L26.6394 31.7762C26.9659 31.8541 27.2438 31.5291 27.1173 31.2189L21.9442 18.5391L10.8525 23.0651L16.0256 35.7449C16.1521 36.0567 16.5785 36.0934 16.7579 35.8096V35.8082Z"
          fill="#F41943"
        />
        <path
          d="M18.6074 24.5469L14.9915 33.2167L11.4551 24.5469H18.6074Z"
          fill="#BA0F38"
        />
        <path
          d="M18.2974 23.0637L17.6946 24.54L15.4668 29.9999L15.3742 30.2248L14.5728 32.1894L13.1229 35.7435C12.9964 36.0552 12.57 36.092 12.3906 35.8082L9.04676 30.513C8.95265 30.3645 8.77473 30.291 8.60268 30.3322L2.50908 31.7776C2.18264 31.8556 1.90472 31.5306 2.03118 31.2189L7.20427 18.5391L18.296 23.0651L18.2974 23.0637Z"
          fill="#F41943"
        />
        <path
          d="M23.462 22.2564C22.9782 22.6872 19.5564 25.6385 14.5759 25.6385C13.6393 25.6385 12.7011 25.5414 11.785 25.3458C9.52931 24.8665 7.41185 23.7886 5.69141 22.2564L7.20745 18.5391L8.94407 19.2478L9.10141 19.3125L14.5759 21.5447L20.0505 19.3125L20.2078 19.2478L21.9444 18.5391L23.4605 22.2564H23.462Z"
          fill="#BA0F38"
        />
        <path
          d="M14.5752 24.5391C21.3515 24.5391 26.8447 19.0458 26.8447 12.2695C26.8447 5.49326 21.3515 0 14.5752 0C7.79893 0 2.30566 5.49326 2.30566 12.2695C2.30566 19.0458 7.79893 24.5391 14.5752 24.5391Z"
          fill="#F9B906"
        />
        <path
          d="M14.5749 23.4507C20.7486 23.4507 25.7534 18.4459 25.7534 12.2722C25.7534 6.09852 20.7486 1.09375 14.5749 1.09375C8.40125 1.09375 3.39648 6.09852 3.39648 12.2722C3.39648 18.4459 8.40125 23.4507 14.5749 23.4507Z"
          fill="#E8A615"
        />
        <path
          d="M14.5748 22.7628C20.3708 22.7628 25.0695 18.0642 25.0695 12.2681C25.0695 6.47207 20.3708 1.77344 14.5748 1.77344C8.77871 1.77344 4.08008 6.47207 4.08008 12.2681C4.08008 18.0642 8.77871 22.7628 14.5748 22.7628Z"
          fill="#F9B906"
        />
        <path
          d="M19.8831 3.21743L5.14763 16.8795C4.46387 15.487 4.08008 13.9224 4.08008 12.2696C4.08008 11.0432 4.29182 9.86687 4.68003 8.77284L12.05 2.08076C12.8587 1.88078 13.7043 1.77344 14.5748 1.77344C16.5099 1.77344 18.3245 2.29986 19.8831 3.21743Z"
          fill="#FFC943"
        />
        <path
          d="M24.3365 8.42008L9.99504 21.716C8.55105 21.0131 7.29086 19.9882 6.30859 18.7339L21.8043 4.67188C22.8998 5.71443 23.7718 6.9908 24.3365 8.42008Z"
          fill="#FFC943"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4567_4578"
          x="0"
          y="0"
          width="29.1484"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4567_4578"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4567_4578"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Prize1;
