import React from "react";
import { Login_bg, Logo2 } from "../../Assets";

const Layout = ({ LoginSubmit, LoginApi }) => {
  const FormHandler = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    LoginSubmit(data.get("email"), data.get("password"));
  };
  return (
    <div className={`w-full h-full`}>
      <div className="w-full h-full flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto flex max-[600px]:flex-col-reverse">
        <div
          className="w-[70%] max-[500]:hidden"
          style={{
            backgroundImage: `url(
            ${Login_bg}
              )`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="w-[30%] p-8 flex flex-col items-center justify-center max-[1000]:w-[50%] max-[800px]:w-[90%] max-[500px]:w-[100%]">
          <img src={Logo2} alt="" />

          <p className="text-xl text-gray-600 text-center">Welcome back!</p>
          <p className="text-center my-2 text-sm">Login with email</p>
          <form className="w-full" onSubmit={FormHandler}>
            <div className="mt-4 w-full max-w-[400px]">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Email Address
              </label>
              <input
                id="email"
                className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                type="email"
                name="email"
                required
              />
            </div>
            <div className="mt-4  w-full max-w-[400px]">
              <div className="flex justify-between">
                <label
                  htmlFor="password"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Password
                </label>
              </div>
              <input
                id="password"
                className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                type="password"
                name="password"
                required
              />
            </div>
            <button
              disabled={LoginApi?.loading}
              type="submit"
              className="mt-8  w-full max-w-[400px] bg-Navy text-white font-bold py-2 px-4 w-full rounded "
            >
              {LoginApi?.loading && (
                <i className="fa-solid fa-circle-notch fa-spin"></i>
              )}{" "}
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Layout;
