import React, { useState, useEffect } from "react";
import Card from "../../Components/Card";
import Linegraph from "../../Components/Linegraph";
import Columnchart from "../../Components/Column";
import Piechart from "../../Components/Pie";
import Areachart from "../../Components/Areachart";
import Usagegraph from "../../Components/Usagegraph";
import { Select, DatePicker } from "antd";
import dayjs from "dayjs";
import User from "../../Components/Svg/user";
import Map from "../../Components/Svg/map";
import University from "../../Components/Svg/university";
import Journal from "../../Components/Svg/journal";
import Tableheader from "../../Components/TableHeader";
import Table from "../../Components/Tabledata";
import Prize1 from "../../Components/Svg/prize1";
import Prize2 from "../../Components/Svg/prize2";
import Prize3 from "../../Components/Svg/prize3";
import Piechart2 from "../../Components/Pie/piechart";
const { RangePicker } = DatePicker;
const Layout = ({
  OverallData,
  Top_college,
  Preferred_Actions,
  JournalData,
  JournalData_Year,
  Filtervalues,
  setFiltervalues,
  RegisterData,
  RegisterData_year,
  Preffered_Goal,
  DownloadExcelHandler,
}) => {
  const [Filterconfig, setFilterconfig] = useState({
    type: [
      // {
      //   value: "yearly",
      //   label: "yearly",
      // },
      {
        value: "monthly",
        label: "monthly",
      },
      // {
      //   value: "Week",
      //   label: "Week",
      // },
      {
        value: "daily",
        label: "daily",
      },
      // {
      //   value: "Custom",
      //   label: "Custom",
      // },
    ],
    year: [],
    month: [
      {
        value: "monthly",
        label: "monthly",
        disabled: true,
      },
      {
        value: "1",
        label: "Jan",
      },
      {
        value: "2",
        label: "Feb",
      },
      {
        value: "3",
        label: "Mar",
      },
      {
        value: "4",
        label: "Apr",
      },
      {
        value: "5",
        label: "May",
      },
      {
        value: "6",
        label: "Jun",
      },
      {
        value: "7",
        label: "Jul",
      },
      {
        value: "8",
        label: "Aug",
      },
      {
        value: "9",
        label: "Sep",
      },
      {
        value: "10",
        label: "Oct",
      },
      {
        value: "11",
        label: "Nov",
      },
      {
        value: "12",
        label: "Dec",
      },
    ],
    week: [],
    days: [],
  });

  const [data, setData] = useState([]);
  const [Pagination, setPagination] = useState(false);
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/bmw-prod/360c3eae-0c73-46f0-a982-4746a6095010.json"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };
  const FilterHandler = (value, type) => {
    setFiltervalues((prev) => ({ ...prev, [type]: value }));
  };
  useEffect(() => {
    let year = new Date().getFullYear();
    let start_year = 2022;
    let Duplicate_year = [
      {
        value: "yearly",
        label: "yearly",
        disabled: true,
      },
    ];
    for (let i = 0; i < year - start_year + 1; i++) {
      Duplicate_year.push({
        value: year - i,
        label: year - i,
      });
    }
    setFilterconfig((prev) => ({ ...prev, year: Duplicate_year }));
  }, []);

  useEffect(() => {
    let LastDate_in_month = new Date(
      Filtervalues?.year,
      Filtervalues?.month,
      0
    ).getDate();
    let No_of_weeks = Math.floor(LastDate_in_month / 7);
    let Duplicate_week = [
      {
        value: "Week",
        label: "Week",
        disabled: true,
      },
    ];
    let Duplicate_days = [
      {
        value: "daily",
        label: "daily",
        disabled: true,
      },
    ];
    for (let i = 0; i < No_of_weeks; i++) {
      Duplicate_week.push({
        value: i + 1,
        label: i + 1,
      });
    }

    for (let i = 0; i < LastDate_in_month; i++) {
      Duplicate_days.push({
        value: i + 1,
        label: i + 1,
      });
    }
    setFilterconfig((prev) => ({
      ...prev,
      days: Duplicate_days,
      week: Duplicate_week,
    }));
  }, [Filtervalues?.year, Filtervalues?.month]);

  return (
    <div className={`w-full h-full p-2`}>
      <div className={`flex items-center flex-wrap justify-between`}></div>

      {/* Graph data */}

      <div className={`w-full hidden`}>
        {/* Multiline and  Bar Graph*/}
        <div className={`w-full flex items-center justify-center`}>
          {/* Multiline */}
          <div className={`w-[70%]`}>
            <Linegraph />
          </div>
          {/* Bar graph */}
          <div className={`w-[30%]`}>
            <Columnchart />
          </div>
        </div>

        {/* Area and Pie */}

        <div className={`w-full flex items-center justify-center`}>
          <div className={`w-[30%]`}>
            <Piechart />
          </div>
          <div className={`w-[70%]`}>
            <Areachart />
          </div>
        </div>

        <div>
          <Usagegraph />
        </div>
      </div>

      <div className="w-full">
        {/* CARD */}
        <div className="flex items-center justify-end px-3">
          <button
            onClick={DownloadExcelHandler}
            className="my-2 bg-Orange text-white px-4 py-2 font-semibold rounded-md"
          >
            <i className="fa-solid fa-download"></i> Download Excel
          </button>
        </div>
        <div className={`w-[100%] flex items-stretch justify-around  `}>
          <div className={`w-[calc(100%/4)] mx-1`}>
            <Card
              bg={"bg-Orange"}
              count={OverallData?.data?.body?.total_active_states}
              icon={<Map />}
              title={"Total states covered"}
            />
          </div>
          <div className={`w-[calc(100%/4)] mx-1`}>
            <Card
              bg={"bg-Navy"}
              count={OverallData?.data?.body?.total_active_colleges}
              icon={<University />}
              title={"Total colleges participated"}
            />
          </div>
          <div className={`w-[calc(100%/4)] mx-1`}>
            <Card
              bg={"bg-Green"}
              count={OverallData?.data?.body?.total_registered_student}
              icon={<User />}
              title={"Total students participated"}
            />
          </div>
          <div className={`w-[calc(100%/4)] mx-1`}>
            <Card
              bg={"bg-Violet"}
              count={OverallData?.data?.body?.total_journal_entered}
              icon={<Journal />}
              title={"Total journal entries"}
            />
          </div>
        </div>
        {/* COLUMN CHART */}
        <div className="flex items-center justify-around p-5">
          <div className="w-[40%]">
            <p className="font-semibold text-md">Student registeration</p>
            {RegisterData_year?.data?.body && (
              <Columnchart
                horizontal={false}
                data={RegisterData_year?.data?.body?.data}
                title={"Register"}
              />
            )}
          </div>
          <div className="w-[40%]">
            <p className="font-semibold text-md">Journal posted</p>
            {JournalData_Year?.data?.body && (
              <Columnchart
                horizontal={false}
                data={JournalData_Year?.data?.body?.data}
                title={"Journal"}
              />
            )}
          </div>
        </div>

        {/* TABLE DATA */}
        <div className="flex items-start justify-around">
          <div
            className={`w-[45%] rounded-md shadow-xl border-2 border-[#ccd9c5] p-2`}
          >
            <Tableheader title={"Top 3 College"} />
            <div className={`w-full pt-4`}>
              <Table
                heading={["Position", "College name", "Journal Posted"]}
                loading={false}
              >
                {Top_college?.data?.body?.length > 0 && (
                  <tbody>
                    {Top_college?.data?.body?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className="text-sm py-2 grid grid-cols-3 border-b-2 border-b-[#ccc]"
                        >
                          <td>
                            {index === 0 && <Prize1 />}
                            {index === 1 && <Prize2 />}
                            {index === 2 && <Prize3 />}
                          </td>
                          <td className="text-center">{item?.college_name}</td>
                          <td className="text-center">
                            {item?.journal_entry_count}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </Table>
            </div>
          </div>
          <div
            className={`w-[45%] rounded-md shadow-xl border-2 border-[#ccd9c5] p-2`}
          >
            <Tableheader title={"Top 3 action"} />
            <div className={`w-full mt-4 max-h-[500px] overflow-y-auto`}>
              <Table
                heading={["Position", "Action name", "Journal Posted"]}
                loading={false}
              >
                {Preferred_Actions?.data?.body?.length && (
                  <tbody>
                    {Preferred_Actions?.data?.body?.map((item, index) => {
                      let count = Pagination
                        ? Preferred_Actions?.data?.body?.length
                        : 3;
                      if (index < count) {
                        return (
                          <tr
                            key={index}
                            className="text-sm py-2 grid grid-cols-3 border-b-2 border-b-[#ccc]"
                          >
                            <td>
                              {index === 0 && <Prize1 />}
                              {index === 1 && <Prize2 />}
                              {index === 2 && <Prize3 />}
                              {index >= 3 && (
                                <p className="pl-2 font-bold text-md text-[#aaa]">
                                  {index + 1}
                                </p>
                              )}
                            </td>
                            <td className="text-center">{item?.action_desc}</td>
                            <td className="text-center">{item?.count}</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                )}
              </Table>
            </div>
            <div className="flex items-center my-1 justify-center">
              <button
                className="font-semibold"
                onClick={() => setPagination(!Pagination)}
              >
                {Pagination ? "Less more" : "Show more"}
              </button>
            </div>
          </div>
        </div>

        {/* PIE CHART */}
        {/* <Piechart /> */}
        <p className={`font-semibold text-lg`}>SDG preferred goals</p>
        <div className="my-4 flex items-center justify-center">
          <Piechart2 data={Preffered_Goal?.data?.body?.goal_based_analysis} />
        </div>
        {/* LIST OF GOALS */}
        <div className={`w-[100%] mx-1 my-10 hidden`}>
          <div
            className={`p-2 rounded-md bg-white shadow-xl border-2 border-[#ccd9c5]`}
          >
            <p className={`font-semibold text-lg`}>SDG preferred goals</p>
            {!Preffered_Goal?.data?.body?.goal_based_analysis?.length && (
              <p className="text-center font-semibold my-2">No Data</p>
            )}
            {Preffered_Goal?.data?.body?.goal_based_analysis && (
              <div className={`w-full py-2 grid grid-cols-2`}>
                {Preffered_Goal?.data?.body?.goal_based_analysis?.map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        className={`w-full  grid grid-cols-7 content-center items-center my-2`}
                        title={item?.goal_title}
                      >
                        <div className="col-span-3 flex items-center">
                          <img
                            src={item?.goal_icon}
                            alt={item?.goal_title}
                            className={`w-[50px] bg-[${item?.icon_color}] aspect-square object-contain p-2 rounded-md`}
                          />
                          <p className={`pl-2 text-sm`}>{item?.goal_title}</p>
                        </div>

                        <div
                          className={`rounded-full bg-[#ccc5] overflow-hidden h-[15px] col-span-3 mx-1 p-1`}
                        >
                          <div
                            className={`h-full w-[${item?.usage_percent}] bg-[${item?.icon_color}] rounded-full`}
                          ></div>
                        </div>
                        <p
                          className={`col-span-1 px-1 text-sm font-medium text-left`}
                        >
                          {item?.user_count}/
                          {Preffered_Goal?.data?.body?.total_registration}
                        </p>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
        </div>

        {/* FILTER */}
        <div className={`w-full my-3 flex items-center`}>
          <p className={`mr-5 text-sm text-gray-500 font-semibold`}>Filter :</p>
          <div className={`w-[100px]`}>
            <Select
              value={Filtervalues.type}
              style={{
                width: "100%",
              }}
              options={Filterconfig?.type}
              name="type"
              onChange={(e) => FilterHandler(e, "type")}
            />
          </div>
          <div className={`flex ml-20`}>
            {Filtervalues?.type !== "Custom" && (
              <div>
                <Select
                  className={`mx-5`}
                  value={Filtervalues.yearly}
                  style={{
                    width: "100%",
                  }}
                  options={Filterconfig?.year}
                  name="yearly"
                  onChange={(e) => FilterHandler(e, "yearly")}
                />
              </div>
            )}
            {Filtervalues?.type !== "Custom" &&
              Filtervalues?.type !== "monthly" &&
              Filtervalues?.type !== "yearly" && (
                <div>
                  <Select
                    className={`mx-5`}
                    value={Filtervalues.monthly}
                    style={{
                      width: "100%",
                    }}
                    options={Filterconfig?.month}
                    name="monthly"
                    onChange={(e) => FilterHandler(e, "monthly")}
                  />
                </div>
              )}
            {Filtervalues?.type !== "Custom" &&
              Filtervalues?.type !== "yearly" &&
              Filtervalues?.type !== "daily" &&
              Filtervalues?.type !== "monthly" && (
                <div>
                  <Select
                    className={`mx-5`}
                    value={Filtervalues.week}
                    style={{
                      width: "100%",
                    }}
                    options={Filterconfig?.week}
                    name="week"
                    onChange={(e) => FilterHandler(e, "week")}
                  />
                </div>
              )}
            {Filtervalues?.type !== "Custom" &&
              Filtervalues?.type !== "yearly" &&
              Filtervalues?.type !== "monthly" &&
              Filtervalues?.type !== "daily" &&
              Filtervalues?.type !== "Week" && (
                <div>
                  <Select
                    className={`mx-5`}
                    value={Filtervalues.daily}
                    style={{
                      width: "100%",
                    }}
                    options={Filterconfig?.days}
                    name="days"
                    onChange={(e) => FilterHandler(e, "daily")}
                  />
                </div>
              )}
            {Filtervalues?.type === "Custom" && (
              <div>
                <RangePicker
                  className={`mx-5`}
                  // defaultValue={dayjs("01/01/2015", dateFormatList[0])}
                  value={Filtervalues.multiday}
                  format={dateFormatList}
                  name="multiday"
                  minDate={dayjs("2022-06-01", "YYYY-MM-DD")}
                  maxDate={dayjs(
                    new Date().toISOString().split("T")[0],
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) => FilterHandler(e, "multiday")}
                />
              </div>
            )}
          </div>
        </div>

        {/* AREA */}
        <div className="w-full flex items-start justify-center">
          {RegisterData?.data?.body?.data && (
            <div className={`w-[50%] mx-auto `}>
              <p className="text-md font-semibold">Registeration entries</p>
              <Areachart
                data={RegisterData?.data?.body?.data}
                color={"#F58334"}
                y={"No.of Register"}
                x={Filtervalues?.type === "monthly" ? "Month" : "No.of Days"}
              />
            </div>
          )}
          {JournalData?.data?.body?.data && (
            <div className={`w-[50%] mx-auto `}>
              <p className="text-md font-semibold">Journal entries</p>

              <Areachart
                data={JournalData?.data?.body?.data}
                color={"#1B56A3"}
                y={"No.of Journal"}
                x={Filtervalues?.type === "monthly" ? "Month" : "No.of Days"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
